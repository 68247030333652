import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	Field,
	Form,
	Heading,
	HorizontalRule,
	Input,
	Label,
	MessageBar,
	TextLink,
} from '@troon/ui';
import { createEffect, createSignal, For, Match, Show, Switch } from 'solid-js';
import { clientAction, cachedGet } from '@troon/api-client';
import { useAction, useSubmission, createAsync } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { useTrackEvent } from '@troon/analytics';
import { StripeProvider } from '../../../../providers/stripe';
import { usePersisted } from '../../../../providers/persistence-store';
import { getConfigValue } from '../../../../modules/config';
import { objectToFormData } from '../../../../graphql/form-data';
import { useUser } from '../../../../providers/user';
import { Receipt } from '../../../access/checkout/_components/receipt';
import { revalidate } from '../../../../graphql/cache';
import { PaymentMethodSelector } from './select-payment-method';
import type { ActiveSubscriptionQuery } from '../../../../graphql';

type Props = { subscription: NonNullable<ActiveSubscriptionQuery['subscription']> };

export function UnlimitedGuestPassDialog(props: Props) {
	const [isDialogOpen, setIsDialogOpen] = createSignal(false);
	const trackEvent = useTrackEvent();
	const [persistedPromoCode] = usePersisted();
	const user = useUser();
	const [promoCode, setPromoCode] = createSignal(persistedPromoCode?.promoCode?.value);
	const paymentMethods = createAsync(() => getPaymentMethods({}));
	const [selectedPaymentMethod, setSelectedPaymentMethod] = createSignal<string>();
	const fetchPreviewInvoice = useAction(getUnlimitedGuestPassPreview);
	const invoicePreview = useSubmission(getUnlimitedGuestPassPreview);
	const purchaseConfirmation = useSubmission(purchaseUnlimitedGuestPassAction);
	const [hasInitialized, setHasInitialized] = createSignal(false);

	createEffect(() => {
		const methods = paymentMethods();
		if (methods?.payment_methods?.length) {
			const defaultMethod = methods.payment_methods.find((m) => m.default)?.id;
			setSelectedPaymentMethod(defaultMethod || methods.payment_methods[0]?.id);
		}
	});

	createEffect(() => {
		if (isDialogOpen()) {
			trackEvent(
				'upsellVisible',
				{
					productType: 'addOn',
					location: 'Account Access Page',
					productName: 'Unlimited Guest Passes',
				},
				{ noDuplicates: true },
			);
		}
	});

	createEffect(() => {
		if (purchaseConfirmation.result && invoicePreview.result) {
			trackEvent('checkout', {
				productType: 'addOn',
				productName: 'Unlimited Guest Passes',
				value: parseFloat(invoicePreview.result.amountDueToday.replace('$', '').replace(',', '')),
			});
		}
	});
	createEffect(() => {
		if (isDialogOpen() && !hasInitialized() && !props.subscription.unlimitedGuestPass) {
			setHasInitialized(true);
			const formData = objectToFormData({
				subscription_id: props.subscription.id,
				promo_code: promoCode(),
				payment_method_id: selectedPaymentMethod(),
			});
			fetchPreviewInvoice(formData);
		}
	});

	createEffect(() => {
		if (invoicePreview.result && promoCode()) {
			trackEvent('applyPromoCode');
		}
	});

	const handlePaymentMethodAdded = async (paymentMethodId: string) => {
		setSelectedPaymentMethod(paymentMethodId);
	};

	return (
		<Dialog key="guest-pass-preview" open={isDialogOpen()} onOpenChange={setIsDialogOpen}>
			<DialogTrigger size="sm" class="size-fit grow-0">
				Add Unlimited Guest Passes
			</DialogTrigger>
			<DialogContent header="Add Unlimited Guest Passes" headerLevel="h2" height="full-max">
				<div class="flex flex-col gap-4">
					<Switch fallback={<ActivityIndicator />}>
						<Match when={purchaseConfirmation.result}>
							{(data) => (
								<Show when={user()?.me}>
									{(me) => (
										<div class="flex flex-col gap-8">
											<div class="flex flex-col items-center gap-6 text-center">
												<IconCircleCheckCustom class="text-8xl" />
												<Heading as="h1" size="h3">
													Your Unlimited Guest Passes are now active!
												</Heading>
												<p class="text-lg">We've sent a confirmation email to {me().email}.</p>
											</div>
											<div class="flex flex-col gap-4 rounded border border-neutral p-6">
												<h2 class="text-xl font-semibold">Order summary</h2>
												<p>
													<Switch>
														<Match when={data() && data().nextInvoiceDate}>
															{(nextInvoiceDate) => (
																<>
																	Your membership will renew automatically on{' '}
																	{dayjs(nextInvoiceDate().date).format('MM/DD/YYYY')}{' '}
																</>
															)}
														</Match>
														<Match when={data().endDayTime}>
															{(endDayTime) => (
																<>
																	Your membership will not auto-renew and will end on{' '}
																	{dayjs(endDayTime().date).format('MM/DD/YYYY')}{' '}
																</>
															)}
														</Match>
													</Switch>
													You can manage your membership through your{' '}
													<TextLink href="/account/access">account settings</TextLink>.
												</p>
												<Receipt
													discount={data().discountAmount}
													subtotal={data().subtotalAmount}
													tax={data().taxAmount}
													total={data().totalAmount}
												/>
											</div>
											<Button appearance="primary" onClick={() => setIsDialogOpen(false)}>
												Close
											</Button>
										</div>
									)}
								</Show>
							)}
						</Match>
						<Match when={invoicePreview.error}>
							{(err) => (
								<MessageBar appearance="danger">
									{
										// @ts-expect-error TODO: ensure error typing is present on ApiResponse
										err.displayMessage ?? 'An error occurred. Please try again.'
									}
								</MessageBar>
							)}
						</Match>
						<Match when={invoicePreview.result}>
							{(preview) => (
								<>
									<div class="flex flex-col gap-4">
										<div class="flex flex-col gap-3 rounded border border-neutral p-6">
											<Heading as="h5" class="text-lg font-semibold">
												Payment info
											</Heading>

											<div class="flex flex-col gap-2">
												<For each={preview().items}>
													{(item) => (
														<>
															<div class="flex items-center justify-between gap-2">
																<span>{item.name}</span>
																<span class={`font-semibold ${item.price < 0 ? 'text-green-500' : ''}`}>
																	{item.priceDisplay}
																</span>
															</div>
															<Show when={item.description}>
																<span class="-mt-2 text-sm">{item.description}</span>
															</Show>
														</>
													)}
												</For>
												<HorizontalRule />
												<div class="flex items-center justify-between">
													<span class="font-semibold">Total Due Now</span>
													<span class="font-semibold">{preview().amountDueToday}</span>
												</div>

												<p class="text-sm text-neutral-800">
													Your {props.subscription.name} membership will auto-renew on {preview().renewalDate} at{' '}
													{preview().renewalPrice}.
												</p>
											</div>
										</div>
										<Form action={getUnlimitedGuestPassPreview}>
											<input type="hidden" name="subscription_id" value={props.subscription.id} />
											<input type="hidden" name="payment_method_id" value={selectedPaymentMethod()} />
											<Field name="promo_code" class="relative flex items-center">
												<Label class="sr-only">Promo Code</Label>
												<Input
													type="text"
													placeholder="PROMO CODE"
													value={promoCode()}
													onInput={(e) => setPromoCode(e.currentTarget.value)}
													class="w-full"
												/>
												<Button type="submit" disabled={!promoCode()} class="absolute right-1 p-2">
													Apply
												</Button>
											</Field>
										</Form>
										<div class="flex-shrink-0">
											<StripeProvider mode="setup" paymentMethodCreation="manual">
												<PaymentMethodSelector
													data={paymentMethods()}
													selectedPaymentMethod={selectedPaymentMethod()}
													onSelect={setSelectedPaymentMethod}
													onPaymentMethodAdded={handlePaymentMethodAdded}
												/>
											</StripeProvider>
										</div>

										<Form action={purchaseUnlimitedGuestPassAction}>
											<input type="hidden" name="subscription_id" value={props.subscription.id} />
											<input type="hidden" name="payment_method_id" value={selectedPaymentMethod()} />
											<input type="hidden" name="promo_code" value={promoCode()} />
											<Button class="w-full" appearance="primary" type="submit" disabled={!selectedPaymentMethod()}>
												Complete Purchase
											</Button>
										</Form>
										<p class="text-center text-sm">
											By clicking "Complete Purchase", you are agreeing to Troon's{' '}
											<TextLink target="_blank" href="/access/terms">
												Terms of Service
											</TextLink>{' '}
											and{' '}
											<TextLink target="_blank" href={`https://${getConfigValue('HOST')}/privacy-policy/`}>
												Privacy Policy
											</TextLink>
											.
										</p>
									</div>
								</>
							)}
						</Match>
					</Switch>
				</div>
			</DialogContent>
		</Dialog>
	);
}

const purchaseUnlimitedGuestPassAction = clientAction(
	'post',
	'/v0/unlimited-guest-pass/upgrade',
	{},
	{
		onSuccess: async () => {
			const artificialTimeoutMs = 5_000;
			setTimeout(async () => {
				await revalidate('activeSubscription');
			}, artificialTimeoutMs);
		},
	},
);
const getPaymentMethods = cachedGet('/v0/troon-access/payment-methods');

const getUnlimitedGuestPassPreview = clientAction('post', '/v0/unlimited-guest-pass/preview');
