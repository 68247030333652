import { IconGuestPassCustom } from '@troon/icons/guest-pass-custom';

import { Heading, Section } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { onMount } from 'solid-js';
import { UnlimitedGuestPassDialog } from './unlimited-guest-pass-dialog';
import type { ActiveSubscriptionQuery } from '../../../../graphql';

type Props = {
	subscription: NonNullable<ActiveSubscriptionQuery['subscription']>;
};
export function UnlimitedGuestPassCard(props: Props) {
	const trackEvent = useTrackEvent();
	onMount(() => {
		trackEvent(
			'upsellClicked',
			{
				location: 'Account Access Page',
				productType: 'addOn',
				productName: 'Unlimited Guest Passes',
			},
			{ noDuplicates: true },
		);
	});
	return (
		<div class="flex flex-col gap-4">
			<Section appearance="contained">
				<div class="flex sm:flex-col md:flex-row md:items-center md:justify-between">
					<div class="flex flex-col gap-3">
						<Heading as="h3" size="h5">
							Unlimited Guest Passes
						</Heading>
						<p class="text-base xl:max-w-lg">
							Extend your discounted Troon Access rate to your entire group whenever you book a tee time.
						</p>
						{/*TODO: make this dynamic from backend*/}
						<p class="mt-1">+$75/mo (billed annually)</p>
						<UnlimitedGuestPassDialog subscription={props.subscription} />
					</div>

					<div class="hidden md:block">
						<IconGuestPassCustom class="h-52 w-72" />
					</div>
				</div>
			</Section>
		</div>
	);
}
